import styled from 'styled-components';
import { Container } from 'reactstrap';

export const HomeMainDiv = styled.div`
  display:  flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(180deg, rgba(255, 153, 187, 0.25) 0%, rgba(135, 135, 255, 0.25) 100%), #FFF;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  
    & > div {
      width: 100%;
    }
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
    
    div > h1 {
      max-width: 435px;
    }

    @media (max-width: 1440px) {
      max-width: 1300px;
    }

    @media (max-width: 1000px) {
      max-width: 900px;
    }

    @media (max-width: 768px) {
      max-width: 700px;
      flex-direction: column;
    }
`;

export const ImgDiv = styled.div`
    img {
      width: 475px;
    }
    
    @media (max-width: 1000px) {
      width: 100%;
      display: flex;
      justify-content: center;
      
     & > img {
         height: 320px;
          width: 360px;
      }
    }
`;

export const FooterDiv = styled.div`
    
    & > div {
        width: 50%;
        display: flex;
        gap: 106px;
    }
    
    @media (max-width: 768px) {

        & > div {
            width: 100%;
        }
    }
`;
