import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'reactstrap';

import TopBar from './topbar';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey24, StepContent } from '../styles/styled-components/GlobalStyle';
import { convertPropertiesToReduxPropertiesArray, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { getCurrPropertyValidInfo, getSelectedSetFromObjectEntries, convertAndCheckedValidOfValueToReduxSave } from '../utils/formUtils';
import ButtonALink from '../components/ButtonALink';
import { setSelectedDataApp, updatePropertyApp } from '../actions/applicationAction';
import { checkIsFormValid, checkIsPropValid, getErrorValidMsg, isPropertyInInvalidArray } from '../utils/validationFunc';
import ErrorPage from '../components/ErrorPage';
import { updatePropertyExecutionQcgDataValue } from '../actions/executionActions';
import FileDefineModule from '../components/FileDefineModule';

const StepApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currFileData = useRef();
  const currLang = navigator.language;
  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ statusValidStep, setStatusValidStep ] = useState();
  const [ showModal, setShowModal ] = useState();

  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const schemaFormData = getStepFormData("Application");
  const selectedApp = useSelector((state) => state.application.get('selectedOption'));
  const appsProperties = useSelector((state) => state.application.get('properties'));
  const selectedAppProps = useSelector((state) => state.application.getIn([ 'properties', selectedApp ]));
  const requiredProps = schemaFormData[selectedApp]?.required;
  const arrayApplicationTypes = getSelectedSetFromObjectEntries(schemaFormData);
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const qcgExecutionProps = useSelector((state) => state.execution.get('qcgProperties'));
  const contentData = getContentOfStep("application"); 
  
  const handleTopbarBtnClick  = () => {
    checkIsFormValid(selectedApp, requiredProps, selectedAppProps, setInvalidProps, setStatusValidStep, "application");
  };

  const handleChangeApplicationType = (event) => {
    let currProps = appsProperties.get(event.value);
    if (currProps.size === 0) {
      currProps = convertPropertiesToReduxPropertiesArray(schemaFormData[event.value].properties);
    }
    dispatch(setSelectedDataApp(event.value, currProps));
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps)) {
      dispatch(updatePropertyApp(selectedApp, propertyName, value));
    }
  };  

  const handleChangePropValue = (value, propertyName, validInfo) => {
    checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps);
    dispatch(updatePropertyApp(selectedApp, propertyName, value));
  };

  const renderSchemaJsonFormData = () => {
    const formElem = schemaFormData[selectedApp].properties;
    let currPropertyRedux;  
    let isInvalid;
    return (
      <>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedAppProps.find(property => property.get('name') === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.get("value"), getCurrPropertyValidInfo(item[0], selectedAppProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.includes(item[0])}
            isRowElemDirection={true}
            name={item[0]}
            placeholder={item[1].placeholder || t('card.none')}
            typeOfInput={currPropertyRedux.getIn([ "validInfo", "type" ])}
            value={currPropertyRedux.get("value")}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedAppProps))}
            handleChange={(e) => handleChangePropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedAppProps))}
          />;
        })
        }
      </>
    );
  };
  
  const handleConfirmSaveFileData = (value, fileFormat) => {
    let result = {};
    result.resource_type = fileFormat;
    if (fileFormat === "path") {
      result.path = value;
    }
    else {
      result.content = value;
    }

    dispatch(updatePropertyExecutionQcgDataValue(currFileData.current.name, result));
    currFileData.current = null;
    setShowModal(false);
  };

  const handleChangePropValueQcgParam = (event, propertyName, validInfo) => {
    if (validInfo.type === "fileModal") {
      currFileData.current = null;
      currFileData.current = { name: propertyName, value: qcgExecutionProps.find(property => property.get('name') === propertyName)?.get("value") };
      setShowModal(true);
    }
    else {
      const value = convertAndCheckedValidOfValueToReduxSave(event, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps);
      dispatch(updatePropertyExecutionQcgDataValue(propertyName, value));
    }
  };

  const handleBlurPropValueQcgParam = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps)) {
      dispatch(updatePropertyExecutionQcgDataValue(propertyName, value));
    }
  };  

  const renderQCGSchemaJsonFormData = () => {
    const data = qcgExecutionProps.toJS();
    let isInvalid;
    return (
      <>
        {data.map(item => {
          if (item.step === "application") {
            isInvalid = isPropertyInInvalidArray(item, invalidProps);  
            return <CustomInput
              key={"key_"+item.name}
              label={item.title}
              invalidMsg={isInvalid ? getErrorValidMsg(item.value, getCurrPropertyValidInfo(item.name, qcgExecutionProps)) : ""}
              isInvalid={isInvalid}
              isRequired={item.validInfo.required}
              isRowElemDirection={true}
              name={item.name}
              placeholder={item.placeholder || t('card.none')}
              typeOfInput={item.validInfo.type}
              value={item.value}
              handleBlur={(e) => handleBlurPropValueQcgParam(e.target.value, item.name, getCurrPropertyValidInfo(item.name, qcgExecutionProps))}
              handleChange={(e) => handleChangePropValueQcgParam(e, item.name, getCurrPropertyValidInfo(item.name, qcgExecutionProps))}
            />;
          }
          return null;
        })}
      </>
    );
  };

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_application.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick} statusValid={statusValidStep}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_application.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/application/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData?.description[currLang]}</Para14>
        <BoxGrey24>
          <Form>
            <CustomInput
              typeOfInput={"select"}
              isRowElemDirection={true}
              isInputDisabled={false}
              isVisible={true}
              name="application_type"
              label={t('step_application.label_type')}
              placeholder=""
              selectOptions={arrayApplicationTypes}
              value={arrayApplicationTypes.find(type => type.value === selectedApp)}
              handleChange={(e) => handleChangeApplicationType(e)}
            />
            { selectedApp && renderSchemaJsonFormData() }
            {renderQCGSchemaJsonFormData()}
          </Form>
        </BoxGrey24>
        <FileDefineModule
          isFileContentModal={false}
          isShowModal={showModal}
          fileData={currFileData.current?.value || null}
          onClickCancelModal={()=>setShowModal(false)}
          onClickConfirmModal={handleConfirmSaveFileData}
        />
      </StepContent>
    </div>
  );
};

export default StepApplication;