import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TopBar from './topbar';
import FormCardContainer from './FormCardContainer';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import ButtonALink from '../components/ButtonALink';
import { checkIsPropValidCustom } from '../utils/validationFunc';
import ErrorPage from '../components/ErrorPage';
import { StepContent } from '../styles/styled-components/GlobalStyle';

const StepParameters = () => {
  
  const { t } = useTranslation();
  const [ statusValidStep, setStatusValidStep ] = useState();
  const [ invalidProps, setInvalidProps ] = useState({});
  const [ nextStepFlag, setNextStepFlag ] = useState(false);
  const [ propsDuplicateNameFormIds, setPropsDuplicateNameFormIds ] = useState([]);
  const arrayParamName = useRef([]);
  
  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const formData = useSelector((state) => state.formsData.getIn([ state.steps.get('stepCurrent') ]));
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  
  useEffect(() => {
    if (currentStepNo === 0 && formData.length > 0 && arrayParamName.current.length === 0) {
      formData.forEach((form) => {
        form.inputsData.forEach(inputData => {
          if (inputData.name === "name" && inputData.value) {
            if (arrayParamName.current.length > 0) {
              const idxNameKey = arrayParamName.current?.find(item => item.key === inputData.name);
              if (idxNameKey) {
                arrayParamName.current[idxNameKey].formIds.push(inputData.id);
              }
              else {
                arrayParamName.current.push({ key: inputData.value, formIds: [ form.id ] });
              }
            }
            else {
              arrayParamName.current.push({ key: inputData.value, formIds: [ form.id ] });
            }
          }
        });
      });
    }
  }, [ currentStepNo, formData ]);
  
  const checkAllFormsIsValid = () => {

    formData.forEach((form) => {
      form.inputsData.forEach(inputData => {
        if (inputData.fieldType === "basic") {
          checkIsPropValidCustom(
            inputData.value,
            inputData.name,
            { type: inputData.type },
            invalidProps,
            setInvalidProps,
            inputData.required,
            form.id
          );
        }
        else {
          const tempData = inputData.options[inputData.value];
          tempData.properties.forEach(property => {
            if (property.type === "select") return;
            if (property.disabled) return;
            checkIsPropValidCustom(
              property.value,
              property.name,
              { type: property.type },
              invalidProps,
              setInvalidProps,
              property.required,
              form.id
            );
          });
        }
      });
    });
  };

  const handleTopbarBtnClick = () => {
    if (propsDuplicateNameFormIds.length > 0) {
      setNextStepFlag(false);
      return;
    }
    
    checkAllFormsIsValid();
    setNextStepFlag(true);
  };
  
  useEffect(() => {

    if (nextStepFlag) {
      let isAllFormsCorrect = true;

      //TODO tutaj tez sprawdzic propsDuplicateNameFormIds czyjest puste, jesli nie to tez "isAllFormsCorrect = false"
      Object.keys(invalidProps).forEach((key) => {
        if (invalidProps[key]?.length > 0) {
          isAllFormsCorrect = false;
        }
      });
  
      setStatusValidStep(isAllFormsCorrect);

      if (!isAllFormsCorrect) {
        setNextStepFlag(false);
      }
    }
  }, [ invalidProps, nextStepFlag ]);

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_parameters.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick} statusValid={statusValidStep}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_parameters.title')}</TitleH3>
          {linkDocumentation
            && <ButtonALink
              href={linkDocumentation + "concepts/parameters/"}
              icon="documentation"
            />
          }
        </div>
        <Para14 className='mt-2 mb-4'>{t('step_parameters.description')}</Para14>
        <FormCardContainer invalidProps={invalidProps} setInvalidProps={setInvalidProps}
          propsDuplicateNameFormIds={propsDuplicateNameFormIds} setPropsDuplicateNameFormIds={setPropsDuplicateNameFormIds}
          arrayParamName={arrayParamName.current}/>
      </StepContent>
    </div>
  );
};

export default StepParameters;
