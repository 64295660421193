import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'reactstrap';

import TopBar from './topbar';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey24, StepContent } from '../styles/styled-components/GlobalStyle';
import { convertPropertiesToReduxPropertiesArray, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { getSelectedSetFromObjectEntries, getCurrPropertyValidInfo } from '../utils/formUtils';
import ButtonALink from '../components/ButtonALink';
import { setSelectedDataDecoder, updatePropertyDecoder } from '../actions/decoderActions';
import { getErrorValidMsg, checkIsPropValid, checkIsFormValid, isPropertyInInvalidArray } from '../utils/validationFunc';
import FileDefineModule from '../components/FileDefineModule';
import ErrorPage from '../components/ErrorPage';

const StepDecoder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = navigator.language;
  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ showFileUploadModal, setShowFileUploadModal ] = useState();
  const [ statusValidStep, setStatusValidStep ] = useState();
  
  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const schemaFormData = getStepFormData("Decoder");
  const selectedDecoder = useSelector((state) => state.decoder.get('selectedOption'));
  const decodersProperties = useSelector((state) => state.decoder.get('properties'));
  const selectedDecoderProps = useSelector((state) => state.decoder.getIn([ 'properties', selectedDecoder ]));
  const requiredProps = schemaFormData[selectedDecoder]?.required;
  const arrayDecoderTypes = getSelectedSetFromObjectEntries(schemaFormData);
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const contentData = getContentOfStep("decoder"); 

  const handleOnTopbarBtnClick  = () => {
    checkIsFormValid(selectedDecoder, requiredProps, selectedDecoderProps, setInvalidProps, setStatusValidStep, "decoder");
  };

  const handleChangeDecoderType = (event) => {
    let currProps = decodersProperties.get(event.value);
    if (currProps.size === 0) {
      currProps = convertPropertiesToReduxPropertiesArray(schemaFormData[event.value].properties);
    }
    dispatch(setSelectedDataDecoder(event.value, currProps));
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps)) {
      dispatch(updatePropertyDecoder(selectedDecoder, propertyName, value));
    }
  };  

  const handleChangePropValue = (value, propertyName, validInfo) => {
    if (validInfo.type === "fileModal") {
      setShowFileUploadModal(true);
    }
    else {
      checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps);
      dispatch(updatePropertyDecoder(selectedDecoder, propertyName, value));
    }
  };

  const handleConfirmSaveFileData = (value, fileFormat) => {
    let result = {};
    result.resource_type = fileFormat;
    if (fileFormat === "path") {
      result.path = value;
    }
    else {
      result.content = value;
    }
    dispatch(updatePropertyDecoder(selectedDecoder,"decoder", result));
    setShowFileUploadModal(false);
  };

  const renderSchemaJsonFormData = () => {
    let formElem = schemaFormData[selectedDecoder].properties;
    let currPropertyRedux;  
    let isInvalid;
    return (
      <>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedDecoderProps.find(property => property.get('name') === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.get("value"), getCurrPropertyValidInfo(item[0], selectedDecoderProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.includes(item[0])}
            isRowElemDirection={true}
            name={item[0]}
            placeholder={item[1].placeholder || t('card.none')}
            typeOfInput={currPropertyRedux.getIn([ "validInfo", "type" ])}
            value={currPropertyRedux.get("value")}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedDecoderProps))}
            handleChange={(e) => handleChangePropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedDecoderProps))}
          />;      
        })
        }
      </>
    );
  };

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_decoder.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleOnTopbarBtnClick} statusValid={statusValidStep}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_decoder.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/decoders/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData?.description[currLang]}</Para14>
        <BoxGrey24>
          <Form>
            <CustomInput
              typeOfInput={"select"}
              isRowElemDirection={true}
              name="decoder_type"
              label={t('step_decoder.label_type')}
              placeholder=""
              selectOptions={arrayDecoderTypes}
              value={arrayDecoderTypes.find(type => type.value === selectedDecoder)}
              handleChange={(e) => handleChangeDecoderType(e)}
            />
            { selectedDecoder && renderSchemaJsonFormData() }
          </Form>
        </BoxGrey24>
        <FileDefineModule
          isShowModal={showFileUploadModal}
          fileData={selectedDecoderProps.find(property => property.get('name') === 'decoder')?.get("value")}
          onClickCancelModal={()=>setShowFileUploadModal(false)}
          onClickConfirmModal={handleConfirmSaveFileData}
        />
      </StepContent>
    </div>
  );
};

export default StepDecoder;
