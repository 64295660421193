import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import TopBar from './topbar';
import FormCardContainer from './FormCardContainer';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import ButtonALink from '../components/ButtonALink';
import { checkIsPropValidCustom } from '../utils/validationFunc';
import { getContentOfStep } from '../utils/jsonSchemaParser';
import ErrorPage from '../components/ErrorPage';
import { StepContent } from '../styles/styled-components/GlobalStyle';

const StepEncoder = () => {
  const { t } = useTranslation();
  const currLang = navigator.language;

  const [ statusValidStep, setStatusValidStep ] = useState();
  const [ invalidProps, setInvalidProps ] = useState({});
  const [ nextStepFlag, setNextStepFlag ] = useState(false);

  const currentStepNo = useSelector((state) => state.steps.get('stepCurrent'));
  const formData = useSelector((state) => state.formsData.getIn([ state.steps.get('stepCurrent') ]));
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const contentData = getContentOfStep("encoder"); 

  const checkAllFormsIsValid = () => {

    formData.forEach((form) => {
      form.inputsData.forEach(inputData => {
        if (inputData.fieldType === "basic") {
          checkIsPropValidCustom(
            inputData.value,
            inputData.name,
            { type: inputData.type },
            invalidProps,
            setInvalidProps,
            inputData.required,
            form.id
          );
        }
        else {
          const tempData = inputData.options[inputData.value];
          tempData.properties.forEach(property => {
            if (property.type === "select") return;
            if (property.disabled) return;
            checkIsPropValidCustom(
              property.value,
              property.name,
              { type: property.type, pattern: property.pattern },
              invalidProps,
              setInvalidProps,
              property.required,
              form.id
            );
          });
        }
      });
    });
  };

  const handleTopbarBtnClick = () => {
    checkAllFormsIsValid();

    setNextStepFlag(true);
  };

  
  useEffect(() => {

    if (nextStepFlag) {
      let isAllFormsCorrect = true;

      Object.keys(invalidProps).forEach((key) => {
        if (invalidProps[key].length > 0) {
          isAllFormsCorrect = false;
        }
      });
  
      setStatusValidStep(isAllFormsCorrect);

      if (!isAllFormsCorrect) {
        setNextStepFlag(false);
      }
    }
  }, [ invalidProps, nextStepFlag ]);

  if (currentStepNo === -1) { //after refresh page
    return (
      <ErrorPage/> 
    );
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_encoder.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick} statusValid={statusValidStep}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_encoder.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/encoders/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData?.description[currLang]}</Para14>
        <FormCardContainer withoutEye invalidProps={invalidProps} setInvalidProps={setInvalidProps} encoder />
      </StepContent>
    </div>
  );
};

export default StepEncoder;
